import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import { GoBackLink } from "@/routes/profile/goBackLink";
import EnvelopeIcon from "@components/icons/EnvelopeIcon";
import ShareExternalLinkIcon from "@components/icons/ShareExternalLinkIcon";
import { Link } from "@components/Link/Link";

export const About = () => {
  return (
    <>
      <GoBackLink />
      <Heading level="h1" margin="top">
        <Trans>Om appen</Trans>
      </Heading>
      <Heading level="h2" margin="top-and-bottom">
        <Trans>Integritetspolicy</Trans>
      </Heading>
      <Text element="p">
        <Trans>
          För att läsa om hur vi hanterar din integritet, kan du ta del av vår
          integritetspolicy.
        </Trans>
      </Text>
      <Text element="p">
        <Link to={t`https://www.medoma.com/se/privacy-policy`} type="external">
          <ShareExternalLinkIcon />
          {t`Integritetspolicy`}
        </Link>
      </Text>
      <Heading level="h2" margin="top-and-bottom">
        <Trans>Teknisk support</Trans>
      </Heading>
      <Text element="p">
        <Trans>
          Om du behöver teknisk hjälp, kontakta din vårdgivare. Om du har
          synpunkter eller förbättringsförlag gällande appen är du välkommen att
          kontakta oss.
        </Trans>
      </Text>
      <Text element="p">
        <Link to="feedback@medoma.com" type="email">
          <EnvelopeIcon /> feedback@medoma.com
        </Link>
      </Text>
    </>
  );
};
