import { useAuth } from "react-oidc-context";
import { z } from "zod";

const unitSchema = z.enum(["alerisahc", "ognvk", "sivnsvt", "medoma"]);

const userProfileSchema = z.object({
  unit: unitSchema,
});

export const useUserProfile = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error(
      "No user found. This is usually because the hook is executed outside of an AuthProvider, or because the authentication is not completed yet.",
    );
  }

  const { unit } = userProfileSchema.parse(user.profile, {
    errorMap: () => ({
      message:
        "Malformed or missing 'unit'. Did you forget the 'organization' scope?",
    }),
  });

  return { unit };
};
