import { UserManager, type UserManagerSettings } from "oidc-client-ts";

let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? () => `https://localhost:7240/care`
    : (unit: string) => `https://logistics-${unit}-dev.medoma.com/care`;

let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? () => `https://localhost:7240/notification/care`
    : (unit: string) =>
        `https://logistics-${unit}-dev.medoma.com/notification/care`;

let devicesUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? () => `https://localhost:5001`
    : (unit: string) => `https://devices-${unit}-dev.medoma.com`;

let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? () => `https://localhost:7023/care`
    : (unit: string) => `https://pas-${unit}-dev.medoma.com/care`;

let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? `https://localhost:5001`
    : `https://auth-dev.medoma.com`;

let environment = import.meta.env.DEV ? "local" : "dev";

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === "care.medoma.com") {
  authApiUrl = `https://auth.medoma.com`;
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}.medoma.com/care`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}.medoma.com/notification/care`;
  devicesUrl = (unit: string) => `https://devices-${unit}.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}.medoma.com/care`;
  environment = "prod";
} else if (hostname === "care.test.medoma.com") {
  authApiUrl = `https://auth-test.medoma.com`;
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}-test.medoma.com/care`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}-test.medoma.com/notification/care`;
  devicesUrl = (unit: string) => `https://devices-${unit}-test.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}-test.medoma.com/care`;
  environment = "test";
} else if (hostname === "care.demo.medoma.com") {
  authApiUrl = `https://auth-demo.medoma.com`;
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}-demo.medoma.com/care`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}-demo.medoma.com/notification/care`;
  devicesUrl = (unit: string) => `https://devices-${unit}-demo.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}-demo.medoma.com/care`;
  environment = "demo";
}

const managedTabletScopes = [
  // accessLogisticsApiAsPatient
  "logistic.api",
  // accessPatientApiAsPatient
  "patient.api",
  // userProfile
  "openid",
  // Custom scope required for auth service to differentiate between old patient/OTP-based login and new device-based login
  "user_type",
  // To get the unit the user belongs to
  "organization",
];
const oidcConfigForManagedDevice: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-care-reference",
  redirect_uri: `${window.location.origin}/login`,
  scope: managedTabletScopes.join(" "),
};

const unmanagedDeviceScopes = managedTabletScopes.concat([
  "mfa",
  "issued_for_app",
  // To get the unit the user belongs to
  "organization",
]);
const oidcConfigForUnmanagedDevice: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-care-reference",
  redirect_uri: `${window.location.origin}/login`,
  // https://authts.github.io/oidc-client-ts/interfaces/UserManagerSettings.html#scope
  scope: unmanagedDeviceScopes.join(" "),
};

// @ts-expect-error DEVICE_NAME is injected on window object from native app
const deviceName: string | undefined = window.DEVICE_NAME;
const isManagedDevice = deviceName?.startsWith("Medoma") ? true : false;
export const userManager = new UserManager(
  isManagedDevice ? oidcConfigForManagedDevice : oidcConfigForUnmanagedDevice,
);

export const {
  getLogisticsApiUrl,
  getPatientApiUrl,
  getNotificationApiUrl,
  getDevicesUrl,
  AUTH_API_URL,
  IS_MANAGED_DEVICE,
  ENVIRONMENT,
} = {
  getLogisticsApiUrl: (unit: string) => logisticsApiUrl(unit),
  getPatientApiUrl: (unit: string) => patientApiUrl(unit),
  getNotificationApiUrl: (unit: string) => notificationApiUrl(unit),
  getDevicesUrl: (unit: string) => devicesUrl(unit),
  AUTH_API_URL: authApiUrl,
  IS_MANAGED_DEVICE: isManagedDevice,
  ENVIRONMENT: environment,
};
