import { Trans } from "@lingui/react/macro";
import CalendarIcon from "@components/icons/CalendarIcon";
import { usePatient } from "./api/patient";
import styles from "./menu.module.scss";
import { NavLink } from "react-router";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import ChatConversationIcon from "@components/icons/ChatConversation";
import HealthParametersIcon from "@components/icons/HealthParametersIcon";
import { Heading } from "@components/Heading/Heading";
import InformationIcon from "@components/icons/InformationIcon";

const LargeIcon = ({ children }: { children: React.ReactNode }) => {
  return <span className={styles.largeIcon}>{children}</span>;
};

export const Menu = ({
  hasUnreadNotification,
}: {
  hasUnreadNotification: boolean;
}) => {
  const { data: patient } = usePatient();

  return (
    <aside className={styles.menu}>
      {patient ? (
        <Heading level="h2" className={styles.patientName}>
          {patient.name}
        </Heading>
      ) : null}

      <nav>
        <ul className={styles.linkList}>
          <li>
            <NavLink to="/schedule" className={styles.navigationLink}>
              <LargeIcon>
                <CalendarIcon />
              </LargeIcon>
              <span className={styles.linkText}>
                <Trans>Schema</Trans>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/measurements" className={styles.navigationLink}>
              <LargeIcon>
                <HealthParametersIcon />
              </LargeIcon>
              <span className={styles.linkText}>
                <Trans>Mätvärden</Trans>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/chat" className={styles.navigationLink}>
              <LargeIcon>
                <NotificationCircle isVisible={hasUnreadNotification}>
                  <ChatConversationIcon />
                </NotificationCircle>
              </LargeIcon>
              <span className={styles.linkText}>
                <Trans>Chatt</Trans>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile" className={styles.navigationLink}>
              <LargeIcon>
                <InformationIcon />
              </LargeIcon>
              <span className={styles.linkText}>
                <Trans>Information</Trans>
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};
