import { Trans } from "@lingui/react/macro";
import ArrowLeftIcon from "@components/icons/ArrowLeftIcon";
import { Link } from "@components/Link/Link";

export const GoBackLink = () => {
  return (
    <Link to=".." size="base">
      <ArrowLeftIcon />
      <Trans>Tillbaka</Trans>
    </Link>
  );
};
