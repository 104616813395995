import { useUserProfile } from "@/utils/useUserProfile";
import {
  GuideTemplateAlerisAhc,
  imagePathsAlerisAhc,
} from "./guideTemplates/GuideTemplateAlerisAhc";
import {
  GuideTemplateDefault,
  imagePathsDefault,
} from "./guideTemplates/GuideTemplateDefault";
import { GuideTemplateSivNsvt } from "./guideTemplates/GuideTemplateSivNsvt";

type IGuideTemplates = {
  jsxText: JSX.Element;
  imagePath: { 480: string; 768: string; 1366: string };
};

export const useGuideTemplates = (): IGuideTemplates => {
  const { unit } = useUserProfile();

  if (unit === "alerisahc") {
    return {
      jsxText: <GuideTemplateAlerisAhc />,
      imagePath: imagePathsAlerisAhc,
    };
  } else if (unit === "sivnsvt") {
    return {
      jsxText: <GuideTemplateSivNsvt />,
      imagePath: imagePathsDefault,
    };
  } else {
    return {
      jsxText: <GuideTemplateDefault />,
      imagePath: imagePathsDefault,
    };
  }
};
