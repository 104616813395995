import type { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import axios from "axios";
import {
  AUTH_API_URL,
  userManager,
  IS_MANAGED_DEVICE,
  getLogisticsApiUrl,
  getPatientApiUrl,
  getNotificationApiUrl,
} from "../utils/envUtils";
import { User } from "oidc-client-ts";
import { z } from "zod";

const PLACEHOLDER_LOGISTICS = "placeholder-logistics";
const PLACEHOLDER_PATIENT = "placeholder-patient";
const PLACEHOLDER_NOTIFICATION = "placeholder-notification";

export const logisticsApi = axios.create({
  baseURL: PLACEHOLDER_LOGISTICS,
});
export const patientApi = axios.create({
  baseURL: PLACEHOLDER_PATIENT,
});
export const notificationApi = axios.create({
  baseURL: PLACEHOLDER_NOTIFICATION,
});

// On initial load of the application, check for existing user
userManager.getUser().then((user) => {
  if (!user) return;
  const unit = user?.profile.unit as string;
  logisticsApi.defaults.baseURL = getLogisticsApiUrl(unit);
  patientApi.defaults.baseURL = getPatientApiUrl(unit);
  notificationApi.defaults.baseURL = getNotificationApiUrl(unit);
});

// When the user is loaded, and the unit becomes available, update the baseURL accordingly
userManager.events.addUserLoaded(async () => {
  const user = await userManager.getUser();
  const unit = user?.profile.unit as string;
  logisticsApi.defaults.baseURL = getLogisticsApiUrl(unit);
  patientApi.defaults.baseURL = getPatientApiUrl(unit);
  notificationApi.defaults.baseURL = getNotificationApiUrl(unit);
});

const signinRedirect = async () => {
  userManager.signinRedirect({
    state: window.location.pathname,
    ...(IS_MANAGED_DEVICE ? {} : { acr_values: "mfa" }),
  });
};

export const getAuthenticatedUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${AUTH_API_URL}:medoma-care-reference`,
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  if (user?.expired) {
    signinRedirect();
  }

  return user;
};

const injectBearerToken = (
  config: InternalAxiosRequestConfig,
  token: string | undefined,
) => {
  if (config && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const requestHandler = (config: InternalAxiosRequestConfig) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") {
    // Adjust baseURL in test scenario.
    if (config.baseURL === PLACEHOLDER_LOGISTICS) {
      config.baseURL = getLogisticsApiUrl("medoma");
    }
    if (config.baseURL === PLACEHOLDER_PATIENT) {
      config.baseURL = getPatientApiUrl("medoma");
    }
    if (config.baseURL === PLACEHOLDER_NOTIFICATION) {
      config.baseURL = getNotificationApiUrl("medoma");
    }
    return config;
  }
  const unexpiredUser = getAuthenticatedUser();
  return injectBearerToken(config, unexpiredUser?.access_token);
};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const internalErrorStatusSchema = z.object({
  response: z.object({
    status: z.number(),
  }),
});

const isErrorWithStatus = (
  error: unknown,
): error is { response: { status: number } } => {
  return internalErrorStatusSchema.safeParse(error).success;
};

const isUnauthenticatedError = (error: unknown) => {
  return isErrorWithStatus(error) && error.response.status === 401;
};

const isUnauthorizedError = (error: unknown) => {
  return isErrorWithStatus(error) && error.response.status === 403;
};

const errorHandler = (error: unknown) => {
  // This typically happens if:
  // The token is expired, or
  // The token was forcibly invalidated, e.g. by closing the session from an admin panel
  if (isUnauthenticatedError(error)) {
    signinRedirect();
  }

  // This typically happens if:
  // The token is still valid, but the MFA timestamp is too old
  // This should only happen if accepted MFA age is shorter than token lifetime
  if (isUnauthorizedError(error)) {
    signinRedirect();
  }

  return Promise.reject(error);
};

notificationApi.interceptors.request.use(requestHandler);
notificationApi.interceptors.response.use(responseHandler, errorHandler);

logisticsApi.interceptors.request.use(requestHandler);
logisticsApi.interceptors.response.use(responseHandler, errorHandler);

patientApi.interceptors.request.use(requestHandler);
patientApi.interceptors.response.use(responseHandler, errorHandler);
