import { Trans } from "@lingui/react/macro";
import { Text } from "@components/Text/Text";

export const imagePathsAlerisAhc = {
  480: "/care/hospitalAtHome480.avif",
  768: "/care/hospitalAtHome768.avif",
  1366: "/care/hospitalAtHome1366.avif",
};

export const GuideTemplateAlerisAhc = () => {
  return (
    <>
      <Text element="p">
        <Trans>
          Vi är glada att kunna erbjuda dig möjligheten att fortsätta din
          sjukhusvård i ditt eget hem. Här får du samma säkerhet, kvalitet och
          trygghet som på sjukhuset, men med bekvämligheten av att vara hemma.
        </Trans>
      </Text>
      <Text element="p">
        <Trans>
          När du vårdas hemma minskar risken för sjukhusrelaterade
          komplikationer, som till exempel att smittas av en infektion. Vård i
          hemmet brukar också innebära att patienter återhämtar sig snabbare med
          och bättre sömn, rörelse och mat.
        </Trans>
      </Text>
      <Text element="p">
        <Trans>
          Du kan kontakta oss dygnet runt via chatt eller video via din
          surfplatta. Du kommer ha daglig videorond med läkare och
          sjuksköterska. Varje morgon och kväll tar du dina mätvärden med
          medföljande utrustning.
        </Trans>
      </Text>
      <Text element="p">
        <Trans>
          Kvalificerad sjukvårdspersonal kommer hem till dig utifrån dina behov.
          Du kan få besök för exempelvis medicinering, undersökningar och
          provtagningar. Både planerade och akuta hembesök kan göras dygnet
          runt.
        </Trans>
      </Text>
      <Text element="p">
        <Trans>
          Vi ser fram emot att stödja dig i din återhämtning och finns här för
          att hjälpa dig på bästa sätt.
        </Trans>
      </Text>
    </>
  );
};
